.bar{
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 1.5rem;
     width: 100%;
     height: 4rem;
     margin: 0;
     background-color: #c6eaff;
     box-shadow: 0 0 5px #3f3d3d
}

.bar .baslik{
     color: #1C77AC;
     margin: 0;
     padding: 5rem;
}

.bar .logo{
     
     align-self: self-start;
}